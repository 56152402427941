


// TODO: rewrite using class-based, @Component syntax
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import EventHelper from '@/_helpers/event.helper';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import editContact from '@/views/components/promoPage/edit-contact/edit-contact.vue';
import editCompany from '@/views/components/promoPage/edit-company/edit-company.vue';
import { TranslateResult } from 'vue-i18n';

interface IEditContactAndCompanyData {
  currentlyEditingEntity: string;
}

interface IEditContactAndCompanyMethods {
  changeEditingEntity: (entityName: string) => void;
  getEditFormComponentName: () => string;
  showConfirmLeavePopup: (leave?: () => void) => void;
  hideConfirmLeavePopup: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
}

interface IEditContactAndCompanyComputed {
  isEditingContact: boolean;
  isEditingCompany: boolean;
  isEditingExponents: boolean;
  isEventOrganizer: boolean;
  currentlyEditingEntityTitle: TranslateResult;
  event: TEvent;
  contact: TContact;
  promoPage: TPromoPage;
  eventLanguages: string[];
  isPromoPageLoading: boolean;
  hasUnsavedChanges: boolean;
}

const edit = Vue.extend<IEditContactAndCompanyData, IEditContactAndCompanyMethods, IEditContactAndCompanyComputed>({
  name: 'edit',
  components: { editContact, editCompany },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      contactInfoRequested: 'promoPageStore/isLoading',
      event: '_eventStore/event',
    }),
    ...mapGetters('promoPageStore', {
      contact: 'contact',
      promoPage: 'promoPage',
      isPromoPageLoading: 'isPromoPageLoading',
    }),
    ...mapGetters('editFormStore', {
      hasUnsavedChanges: 'hasUnsavedChanges',
    }),
    isEditingContact(): boolean {
      return this.currentlyEditingEntity === 'contact';
    },
    isEditingCompany(): boolean {
      return this.currentlyEditingEntity === 'company';
    },
    isEditingExponents(): boolean {
      return this.currentlyEditingEntity === 'exponents';
    },
    isEventOrganizer(): boolean {
      return EventHelper.isContactEventOrganizer(this.event, this.contact);
    },
    currentlyEditingEntityTitle(): TranslateResult {
      return this.$t('editContactAndCompany.titleEdit_' + this.currentlyEditingEntity);
    },
    eventLanguages(): string[] {
      if (this.event && this.event.languages) {
        return this.event.languages;
      }
      return [];
    },
  },
  data(): IEditContactAndCompanyData {
    return {
      currentlyEditingEntity: 'contact',
    };
  },

  methods: {

    ...mapActions('editFormStore', {
      showConfirmLeavePopup: 'showConfirmLeavePopup',
      hideConfirmLeavePopup: 'hideConfirmLeavePopup',
      setHasUnsavedChanges: 'setHasUnsavedChanges',
      leave: 'leave'
    }),

    /* Sets the name of the entity that is currently being edited
     * accounting for hasUnsavedChanges
     */
    changeEditingEntity(entityName: string): void {

      const leaveAction = (): void => {
        this.setHasUnsavedChanges(false);
        this.hideConfirmLeavePopup();

        if (this.currentlyEditingEntity === entityName) {
          // No need for redundant navigations
          return;
        }

        this.currentlyEditingEntity = entityName;

        this.$router.push({
          name: 'promo-page-edit-' + this.currentlyEditingEntity
        });
      };

      if (this.hasUnsavedChanges) {
        this.showConfirmLeavePopup(leaveAction);
      } else {
        leaveAction();
      }

    },

    /* Returns the name of the component to be shown (standard so-called «dynamic components» practice) */
    getEditFormComponentName(): string {

      switch (this.$route.name) {
        case 'promo-page-edit-contact':
          this.currentlyEditingEntity = 'contact';
          return 'edit-contact';

        case 'promo-page-edit-company':
          this.currentlyEditingEntity = 'company';
          return 'edit-company';

        default:
          this.currentlyEditingEntity = 'contact';
          return 'edit-contact';

      }

    }
  }
});
export default edit;
