<template>
  <div
    v-if="show"
    class="category-chooser"
    ref="categoryChooserPopup"
    :class="{'selected-only' : mode === 'selectedOnly'}"
  >
    <div
      v-if="mode !== 'selectedOnly'"
      class="category-chooser-head relative"
      ref="categoryChooserPopupHead"
    >
      {{ $t('contacts.popups.companyCategoryChooser.title') }}
      <span
        class="cur-p close"
        @click="close"
      />
    </div>
    <div
      class="body"
      ref="categoryChooserPopupBody"
      :class="{ 'body-scroll-ended' : scrollEnded, 'body-scroll-available' : !scrollEnded}"
    >
      <div class="tag-selector">
        <perfect-scrollbar
          class="scroll-categories"
          :style="{'height' : mode === 'selectedOnly' ? 'auto' : scrollAreaHeight }"
          id="scrollCategories"
          ref="categoryChooserPopupScrollArea"
          :options="{ 'wheelPropagation': mode === 'selectedOnly', 'suppressScrollX': true }"
        >
          <choose-company-categories-tree-item
              v-for="(tag, index) in tagTree"
              ref="tagItems"
              :key="index"
              :item="tag"
              :mode="mode"
              :selectedTagIds="selectedTagIds"
              @tagRemoved="returnTagSelection"
              @checkboxSelected="handleTagSelected($event, index)"
          />
        </perfect-scrollbar>
      </div>
    </div>
    <div
      v-if="mode !== 'selectedOnly' && mode !== 'filter'"
      class="category-chooser-footer"
      ref="categoryChooserPopupFooter"
    >
      <div class="actions">
        <span
          class="action-button inline-block"
          @click="returnTagSelection"
        >
          {{ $t('button.apply') }}
        </span>
      </div>
    </div>
    <div
      v-if="mode === 'filter'"
      class="category-chooser-footer"
      ref="categoryChooserPopupFooter"
    >
      <div class="actions filter-actions">
        <button
          class="control control-button control-button-secondary control-button-secondary-border-only action-button action-button-reset inline-block"
          @click="resetFilter()"
        >
          {{ $t('button.reset') }}
        </button>
        <button
          class="control control-button control-button-primary action-button action-button-filter inline-block"
          @click="returnTagSelection"
        >
          {{ $t('button.apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ChooseCompanyCategoriesTreeItem from '@/views/components/popups/chooseCompanyCategoriesTreeItem';

export default {
  name: 'chooseCompanyCategories',
  /* To use setTagSelected() across hierarchy */
  provide() {
    return {
      setTagSelected: this.setTagSelected
    }
  },
  components: {ChooseCompanyCategoriesTreeItem},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String, // used mode names: selectedOnly
      default: 'allTagsMode' // just some string. Default mode name is not used yet
    },
    allTags: {
      type: Array,
      default: function () {
        return [];
      }
    },
    selectedTags: {
      type: Array,
      default: function () {
        return [];
      }
    },
    selectedTagIds: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  watch: {
    allTags: {
      immediate: true,
      handler(newValue) {
        this.initComponent(newValue);
      }
    },
    show: {
      immediate: true,
      handler() {
        if (this.show) {
          this.resizeScrollerHeight();
        }
      }
    },
    selectedTagIds: {
      /* saving to a local variable because we should only mutate the list after Apply button has been clicked */
      handler(newValue) {
        this.localSelectedTags = this.allTags.filter((x) => newValue.indexOf(x.id) >= 0);
      }
    }
  },
  data: function () {
    return {
      scrollAreaHeight: '400px',
      scrollEnded: false,
      tagsByParent: { // first_level: [{}, {}], id: [{}, {}] — temporary structure for buildTagTree()
        first_level: [],
      },
      tagTree: [], // Holds the tag tree structure built in buildTagTree()
      localSelectedTags: [], // Local var to store currently selected tags
      localEventTags: [] // Local var to store whole list of tags for current event
    }
  },
  mounted() {
    this.resizeScrollerHeight();
    window.addEventListener('resize', this.resizeScrollerHeight);
    this.updateLocalSelectedTags();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeScrollerHeight);
  },
  methods: {

    initComponent(tagListFromProp) {
      if (!tagListFromProp) {
        return;
      }

      // marking selected tags isSelected
      const tags = tagListFromProp.map((x) => {
        const clone = {...x};
        if (this.isSelected(clone)) {
          clone.isSelected = true;
        }
        return clone;
      });

      /* saving to a local variable
       */
      this.localEventTags = [...tags];

      /* Build tree from a flat list */
      this.buildTagTree(tags);
      this.updateLocalSelectedTags();
    },

    /* Check if an object id is in the list of selected tags
    */
    isSelected(tag) {
      return this.selectedTagIds.indexOf(tag.id) > -1;
    },

    /* mark an item in flat list as selected or not selected
     */
    setTagSelected(id, selectedState) {
      if (typeof id == 'undefined') return;

      for (let i = 0; i < this.localEventTags.length; i++) {
        if (this.localEventTags[i].id === id) {

          // see «reactivity in depth» in Vue docs
          this.$set(this.localEventTags, i, {
            ...this.localEventTags[i],
            isSelected: selectedState
          });

          // Don't look further if found
          break;
        }
      }

      this.updateLocalSelectedTags();
    },

    /* Prepare local selected tags
     */
    updateLocalSelectedTags() {
      this.localSelectedTags = this.localEventTags.filter((x) => x.isSelected);
    },

    /* Gives the tag selection to parent component
     * by $emitting it
     * Closes the popup as requested in AW-540
     */
    returnTagSelection() {
      this.updateLocalSelectedTags();
      this.$emit('tagListUpdated', this.localSelectedTags);
      this.close();
    },

    /*
     */
    handleTagSelected(eventData, refIndex) {
      this.setTagSelected(eventData.id, eventData.isSelected);
      if (eventData.isSelected === false && eventData.tag.parent_id === 0) {
        this.$refs.tagItems[refIndex].deselect();
      }
    },

    /* Scroller needs to look good. Height should fill all available space
     */
    resizeScrollerHeight() {
      try {
        const prefix = 'categoryChooserPopup';
        const popup = this.$refs[prefix];
        const head = this.$refs[prefix + 'Head'];
        const body = this.$refs[prefix + 'Body'];
        const footer = this.$refs[prefix + 'Footer'];

        let h = popup.getBoundingClientRect().height;
        h = h - parseInt(window.getComputedStyle(popup).getPropertyValue('padding-bottom'), 10);
        h = h - parseInt(window.getComputedStyle(head).getPropertyValue('margin-bottom'), 10);
        h = h - parseInt(window.getComputedStyle(body).getPropertyValue('margin-bottom'), 10);
        h = h - head.getBoundingClientRect().height;
        h = h - footer.getBoundingClientRect().height;

        this.scrollAreaHeight = h + 'px';

      } catch (e) {
        void (0);
      }
    },

    /* Emits «close» event
     */
    close() {
      this.$emit('close');
    },

    /* Recursively attach children to a list node
     */
    attachSubTree(list) {
      for (let i = 0; i < list.length; i++) {
        if (this.tagsByParent[list[i].id]) {
          list[i].children = list[i].children || [];
          list[i].children.push(...this.attachSubTree(this.tagsByParent[list[i].id]));
        }
      }
      return list;
    },

    /* Create a tree from flat structure using parent_id
     */
    buildTagTree(tags) {
      let _tags = [...tags];

      // Empty the temp structure before the run
      this.tagsByParent = {
        first_level: []
      };

      // sorting tags by parent_id into tagsByParent
      for (let tag = 0; tag < _tags.length; tag++) {
        let isSelected = this.isSelected(tag);
        if (_tags[tag].parent_id) {
          this.tagsByParent[_tags[tag].parent_id] = this.tagsByParent[_tags[tag].parent_id] || [];
          this.tagsByParent[_tags[tag].parent_id].push({..._tags[tag], isSelected: isSelected});
        } else {
          this.tagsByParent.first_level.push({..._tags[tag], isSelected: isSelected});
        }
      }
      // adding children to first_level nodes
      this.tagTree = this.attachSubTree(this.tagsByParent.first_level);
    },
    resetFilter() {
      this.$emit('resetSelectedTags', this.localSelectedTags)
    }
  }
}
</script>

<style lang="scss" scoped>
.category-chooser {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $z-index-company-categories-chooser;
  border-radius: 24px;
  background-color: $ew-white;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 640px;
  max-height: 740px;
  padding: 0 30px 23px 37px;

  &.selected-only {
    position: static;
    transform: none;
    width: auto;
    height: auto;
    border-radius: initial;
    max-width: none;
    max-height: none;
    padding: 0;
  }

  .close {
    border-radius: 99px;
    border-style: solid;
    border-width: 1px;
    border-color: #80848b;
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);

    &::before,
    &::after {
      width: 70%;
      height: 2px;
      position: absolute;
      background-color: #80848b;
      left: 15%;
      top: 50%;
      margin-top: -1px;
      transform: rotate(45deg);
      content: "";
    }

    &::after {
      transform: rotate(-45deg);
    }

  }

  .category-chooser-head {
    margin: 0 -30px 40px -37px;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #e1e2eb;
    padding: 27px 30px 23px 37px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.41);
  }

  .body {
    margin-bottom: 5rem;
    position: relative;

    &::after {
      background-color: #d8d8d8;
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
    }
  }

  .actions {
    text-align: center;
  }

  .filter-actions {
    display: flex;
    justify-content: flex-end;
  }

  .action-button {
    padding: 0 1rem;
    border-radius: 99px;
    line-height: 3.6rem;
    cursor: pointer;
    margin-left: 5px;

    &:hover {
      opacity: 0.6;
    }
  }

  .action-button-filter {
    background-color: $ew-red;
  }

}

@media (max-height: 860px) {
  .category-chooser {
    top: calc(50% + 60px);
    transform: translate(-50%, -50%);
    height: calc(100% - 60px);
  }
}

/* Custom scroller customizations */
.app.app-promo-page-edit-company .category-chooser {
  /deep/ .ps__rail-y,
  /deep/ .ps__rail-y:hover {
    width: 4px;
    background-color: #ededef;
    opacity: 1;
  }

  /deep/ .ps__thumb-y,
  /deep/ .ps__thumb-y:hover {
    width: 4px;
    right: 0;
    border-radius: 0;
    background-color: #00b6f8;
    opacity: 1;
  }
}
</style>

<style lang="scss">

  /* No need for overflow: hidden in .selected-only */
  .app.app-promo-page-edit .category-chooser.selected-only .scroll-categories.ps {
    overflow: visible !important; /* Have to use !important to override perfect-scrollbar rule, which has !important */
  }

</style>
